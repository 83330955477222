
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    nativeType: {
      type: String as PropType<'button' | 'submit'>,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '保存'
    }
  },
  setup: (props, { emit }) => {
    return {
      save: () => {
        emit('save')
      }
    }
  }
})
